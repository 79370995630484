import React from 'react';
import { BOSS } from '../partials/store/BOSS';
import { InStock } from '../partials/store/InStock';
import { InStockNearby } from '../partials/store/InStockNearby';
import { Clearance } from '../partials/store/Clearance';
import { LimitedAvailability } from '../partials/store/LimitedAvailability';
import { LimitedAvailabilityNearby } from '../partials/store/LimitedAvailabilityNearby';
import { NotLocalized } from '../partials/store/NotLocalized';
import { NoStoreOption } from '../partials/store/NoStoreOption';
import { LiveGoodsPod } from '../partials/store/LiveGoodsPod';
import { BopisOffMessage } from '../partials/store/BopisOffMessage';
import { EmptyStoreMessage } from '../partials/store/EmptyStoreMessage';
import { dataModel } from '../../components/dataModel';
import {
  getLiveGoodsStock,
  getLiveGoodsStoreName,
  isActiveOrInSeason,
  isItemOnClearance
} from '../../components/helper/utils';
import { FULFILLMENT_METHODS } from '../../components/constants';
import {
  isBOPIS,
  isBOSS,
  isBrowseOnly,
  isBuyInStore,
  isDiscontinued,
  isLimitedStock,
  isInStockYourStore,
  isInStockNearby,
  isLimitedStockNearby,
  isOutOfStockYourStore,
  isAppliance,
  isOutOfStockBoss,
  isOutOfStockOnline,
  getDisplayableInventory,
  getExcludedStateBoss,
  isItemGCC,
  getSellableQuantityYourStore,
  getPickupService,
  getLocalizedStoreStateCode,
  isStateExcluded,
  isClearancePricing
} from './pod-fulfillment-utils';

const getBopisScenarios = ({ customRender, isBOSSInStock, product }) => {
  if (!isBOPIS(product)) {
    return null;
  }

  if (
    getSellableQuantityYourStore(product) > 0 &&
    !isOutOfStockYourStore(product) &&
    (isBrowseOnly(product) || isBuyInStore(product))
  ) {
    return () => <BopisOffMessage product={product} customRender={customRender} />;
  }

  if (getSellableQuantityYourStore(product) === 0 && isBuyInStore(product)) {
    if (!isActiveOrInSeason(product?.fulfillment)) {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable"
          enableOverlayLink={false}
          product={product}
          customRender={customRender}
        />
      );
    }
    return () => (
      <LimitedAvailability product={product} customRender={customRender} bopisNotFulfillableOOS />
    );
  }

  if (!isBOSSInStock && (isBrowseOnly(product) || isLimitedStock(product))) {
    return () => (
      <LimitedAvailability
        product={product}
        customRender={customRender}
        // Display At your store if BOPIS=false, OOS and either Active or In season
        bopisNotFulfillableOOS={
          isBuyInStore(product) &&
          getSellableQuantityYourStore(product) === 0 &&
          isActiveOrInSeason(product?.fulfillment)
        }
      />
    );
  }

  if (isInStockYourStore(product)) {
    return () => <InStock product={product} customRender={customRender} />;
  }
  if (isInStockNearby(product)) {
    return () => <InStockNearby product={product} customRender={customRender} />;
  }
  if (isLimitedStockNearby(product) && !isBOSSInStock) {
    return () => <LimitedAvailabilityNearby product={product} customRender={customRender} />;
  }
};

const getBossScenarios = ({ product, customRender, customer }) => {
  if (!isBOSS(product)) {
    return null;
  }
  const excludedState = getExcludedStateBoss(product);
  if (excludedState) {
    return () => (
      <NoStoreOption
        storeMessage="Unavailable in "
        excludedState={excludedState}
        enableOverlayLink
        product={product}
        customRender={customRender}
      />
    );
  }
  if (isOutOfStockBoss(product)) {
    return () => (
      <NoStoreOption
        storeMessage="Unavailable"
        enableOverlayLink={false}
        product={product}
        customRender={customRender}
      />
    );
  }
  return () => (
    <BOSS
      displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BOSS)}
      customRender={customRender}
    />
  );
};

const getStoreIdScenarios = ({ customRender, customer, isBOSSInStock, product }) => {
  if (!getPickupService(product)) {
    const localizedStoreStateCode = getLocalizedStoreStateCode();
    const excludedStateName = isStateExcluded(
      localizedStoreStateCode,
      'boss',
      product?.fulfillment
    );
    if (excludedStateName) {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable in "
          excludedState={excludedStateName}
          enableOverlayLink
          product={product}
          customRender={customRender}
        />
      );
    }
  }

  if (isItemOnClearance(product?.fulfillment) && isClearancePricing(product)) {
    return () => <Clearance product={product} customRender={customRender} />;
  }

  const bopisScenario = getBopisScenarios({ customRender, isBOSSInStock, product });
  if (bopisScenario) {
    return bopisScenario;
  }

  const bossScenario = getBossScenarios({ product, customRender, customer });
  if (bossScenario) {
    return bossScenario;
  }

  /* -----No BOSS or BOPIS----- */
  // @TODO: What is in stock?
  // if (isInStock(product) || hasZeroStock(product) || isLimitedStock(product)) {
  //   /* Not BOPIS but has qtyForSale */
  //   return <LimitedAvailability localizedStore={localizedStore} channel={channel} />;
  // }
  if (isAppliance(product) || isDiscontinued(product)) {
    return () => <EmptyStoreMessage customRender={customRender} product={product} />;
  }
  if (isOutOfStockYourStore(product) || product?.availabilityType?.type === 'Shared') {
    return () => (
      <NoStoreOption
        storeMessage="Unavailable at "
        enableOverlayLink
        product={product}
        customRender={customRender}
      />
    );
  }
  return () => (
    <NoStoreOption
      storeMessage="Unavailable"
      enableOverlayLink={false}
      product={product}
      customRender={customRender}
    />
  );
};

export const getStoreTemplate = ({ product, storeId, customer, customRender }) => {
  const isGCC = isItemGCC(product);
  const liveGoodsStock = getLiveGoodsStock(product?.fulfillment);
  if (!product || isGCC) {
    return () => <EmptyStoreMessage isGCC={isGCC} customRender={customRender} product={product} />;
  }

  if (liveGoodsStock === 0) {
    return () => (
      <LiveGoodsPod
        storeName={getLiveGoodsStoreName(product)}
        seasonStatusEligible={product.fulfillment?.seasonStatusEligible}
        customRender={customRender}
      />
    );
  }

  if (liveGoodsStock > 0) {
    return () => <InStock product={product} customRender={customRender} />;
  }

  const isBOSSInStock = isBOSS(product) && !isOutOfStockBoss(product);

  if (storeId) {
    return getStoreIdScenarios({ customRender, customer, isBOSSInStock, product });
  }
  
  return () => <NotLocalized customRender={customRender} />;
};

getStoreTemplate.dataModel = dataModel;
