import {
    APPLIANCE_SHIPPING_MIN_MET,
    APPLIANCE_SHIPPING_MIN_NOT_MET,
    BODFS_CAR_SCHEDULE_DELIV,
    SCHEDULE_DELIV,
    SCHEDULE_DELIV_LATER,
    SIX_HOUR_CAR_SCHEDULE_DELIV
  } from '../constants';
  
  const getBodfsText = ({
    bodfsDeliveryTimeline,
    bodfsFulfillment,
    isCartTiles,
    isSameBODFSDeliveryPriceFS
  }) => {
    // $1.99 BODFS message scenario on PIP
    if (isSameBODFSDeliveryPriceFS && !isCartTiles) {
      return {
        titleText:
          BODFS_CAR_SCHEDULE_DELIV.TITLE_TEXT1 +
          bodfsDeliveryTimeline +
          BODFS_CAR_SCHEDULE_DELIV.TITLE_TEXT2 +
          bodfsFulfillment?.deliveryCharge +
          '. ',
        bodyText: BODFS_CAR_SCHEDULE_DELIV.BODY_TEXT,
        isTextWrapping: true
      };
    }
  
    if (bodfsFulfillment?.hasSameDayCarDelivery) {
      return {
        titleText: SIX_HOUR_CAR_SCHEDULE_DELIV.TITLE_TEXT + ' ',
        bodyText: SIX_HOUR_CAR_SCHEDULE_DELIV.BODY_TEXT,
        isTextWrapping: true
      };
    }
  
    // Item is eligible for BODFS and delivery timeline is today/tomorrow
    if (bodfsDeliveryTimeline) {
      if (bodfsDeliveryTimeline !== 'later') {
        return {
          titleText: SCHEDULE_DELIV.TITLE_TEXT + bodfsDeliveryTimeline + '.',
          bodyText: SCHEDULE_DELIV.BODY_TEXT,
          isTextWrapping: true
        };
      }
      return {
        titleText: SCHEDULE_DELIV_LATER.TITLE_TEXT,
        bodyText: SCHEDULE_DELIV_LATER.BODY_TEXT
      };
    }
  };
  
  const getMajorApplianceText = ({
    applianceFlatChargeApplies,
    forceFreeShippingForExchange,
    isCartTiles,
    isMinimumFreeShipPrice
  }) => {
    // FEATURE SWITCH OVERRIDE
    // APPLIANCE Non Exchage Customer with enableApplianceDeliveryCharge
    if (applianceFlatChargeApplies) {
      return {
        titleText: APPLIANCE_SHIPPING_MIN_MET.TITLE_TEXT,
        bodyText: APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT,
        bodyTextAdditional: !isCartTiles ? APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT_ADDITIONAL : ''
      };
    }
  
    // FEATURE SWITCH OVERRIDE
    // APPLIANCE Exchange customer with enableFreeDeliveryForExchange
    if (forceFreeShippingForExchange) {
      return {
        titleText: APPLIANCE_SHIPPING_MIN_MET.TITLE_TEXT,
        bodyText: APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT
      };
    }
  
    // APPLIANCE + Free shipping minimum met
    if (isMinimumFreeShipPrice) {
      return {
        titleText: APPLIANCE_SHIPPING_MIN_MET.TITLE_TEXT,
        bodyText: APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT
      };
    }
  
    // APPLIANCE + Free shipping minimum not met
    return {
      freeText: APPLIANCE_SHIPPING_MIN_NOT_MET.FREE_TEXT,
      titleText: APPLIANCE_SHIPPING_MIN_NOT_MET.TITLE_TEXT,
      bodyText: APPLIANCE_SHIPPING_MIN_NOT_MET.BODY_TEXT,
      isTextWrapping: true
    };
  };
  
  export const getDeliveryMessageContent = ({
    applianceFlatChargeApplies,
    bodfsDeliveryTimeline,
    bodfsFulfillment,
    forceFreeShippingForExchange,
    isBodfsAvailable,
    isCartTiles,
    isMajorApplianceDeliveryType,
    isMinimumFreeShipPrice,
    isSameBODFSDeliveryPriceFS
  }) => {
    let deliveryMessageContent;
    if (isBodfsAvailable) {
      deliveryMessageContent = getBodfsText({
        bodfsDeliveryTimeline,
        bodfsFulfillment,
        isCartTiles,
        isSameBODFSDeliveryPriceFS
      });
    }
  
    if (isMajorApplianceDeliveryType) {
      deliveryMessageContent = getMajorApplianceText({
        applianceFlatChargeApplies,
        forceFreeShippingForExchange,
        isCartTiles,
        isMinimumFreeShipPrice
      });
    }
    return deliveryMessageContent || { titleText: '', bodyText: '' };
  };
  
  export const getDeliveryMessageVars = ({
    applianceDeliveryData,
    enableApplianceDeliveryCharge,
    enableFreeDeliveryForExchange,
    shippingData,
    isBodfsAvailable,
    isExchangeCustomer,
    isMajorAppliance,
    priceForFreeShip
  }) => {
    let isApplianceDataLoading = false;
    let isMinimumFreeShipPrice = false;
    let noDeliveryAvailable = false;
    // Feature switch Overrides
    let applianceFlatChargeApplies = false;
    let forceFreeShippingForExchange = false;
  
    if (isMajorAppliance) {
      isApplianceDataLoading = !applianceDeliveryData;
      isMinimumFreeShipPrice = priceForFreeShip;
    } else {
      noDeliveryAvailable = !shippingData && !isBodfsAvailable;
    }
  
    if (isExchangeCustomer) {
      forceFreeShippingForExchange = enableFreeDeliveryForExchange;
    } else {
      applianceFlatChargeApplies = enableApplianceDeliveryCharge;
    }
  
    return {
      applianceFlatChargeApplies,
      forceFreeShippingForExchange,
      isApplianceDataLoading,
      isMinimumFreeShipPrice,
      noDeliveryAvailable
    };
  };
  