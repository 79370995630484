import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatDate, formatWeek } from './utils';

let storeTimeZone;
let bopisCutOffBeforeStoreClose;
dayjs.extend(utc);
dayjs.extend(timezone);

export const DAYS_OF_THE_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const getDefaultStoreHours = () => (
  {
    friday: { open: '6:00', close: '21:00' },
    monday: { open: '6:00', close: '21:00' },
    saturday: { open: '6:00', close: '21:00' },
    sunday: { open: '8:00', close: '20:00' },
    thursday: { open: '6:00', close: '21:00' },
    tuesday: { open: '6:00', close: '21:00' },
    wednesday: { open: '6:00', close: '21:00' }
  }
);

export const timeZoneFormatted = (storeTZ = '') => {
  switch (storeTZ) {
  case 'EST5EDT': return 'US/Eastern';
  case 'CST6CDT': return 'US/Central';
  case 'PST8PDT': return 'US/Pacific';
  case 'MST7MDT': return 'US/Mountain';
  case 'MST7': return 'MST';
  case 'AST4': return 'Canada/Atlantic';
  case 'HST10': return 'HST';
  case 'YST9MDT': return 'Canada/Yukon';
  case 'YST9YDT': return 'US/Alaska';
  default: return storeTZ;
  }
};

export const setBopisCutOff = (cutOffTime) => {
  bopisCutOffBeforeStoreClose = cutOffTime;
};

export const getDateTimeData = (date, timezoneValue) => {
  let dateTimeData;
  try {
    dateTimeData = dayjs.tz(date, timezoneValue);
  } catch (error) {
    console.warn(error);
    dateTimeData = dayjs(date).utc();
  }
  return dateTimeData;
};

// Store hours utils
export const getTodayDateTZ = () => (
  storeTimeZone
    ? new Date(getDateTimeData(new Date(), storeTimeZone).format('YYYY-MM-DDTHH:mm:ss'))
    : new Date()
);

export const getTomorrowDateTZ = () => {
  const tmrw = new Date(getTodayDateTZ().setDate(getTodayDateTZ().getDate() + 1));
  return storeTimeZone ? new Date(getDateTimeData(tmrw, storeTimeZone).format('YYYY-MM-DDTHH:mm:ss')) : tmrw;
};

export const getTodayDate = () => (storeTimeZone ? getTodayDateTZ() : new Date());

export const getTomorrowDate = () => (storeTimeZone ? getTomorrowDateTZ()
  : new Date(getTodayDate().setDate(getTodayDate().getDate() + 1)));

export const getETADate = () => {
  const etaDate = new Date(getTodayDate().setDate(getTodayDate().getDate() + 2));
  return storeTimeZone ? getDateTimeData(etaDate, storeTimeZone).format('YYYY-MM-DD')
    : dayjs(etaDate).format('YYYY-MM-DD');
};

export const getDayAfterTomorrowDate = () => {
  const dayAfterTmr = new Date(getTodayDate().setDate(getTodayDate().getDate() + 2));
  return storeTimeZone ? new Date(getDateTimeData(dayAfterTmr, storeTimeZone).format('YYYY-MM-DDTHH:mm:ss'))
    : dayAfterTmr;
};

const checkDate = (dateToCheck) => {
  const dateToCheckDate = new Date(dateToCheck);
  return {
    checkDateMonth: dateToCheckDate.getMonth() + 1,
    checkDate: dateToCheckDate.getDate(),
    checkDateYear: dateToCheckDate.getFullYear()
  };
};

export const getStoreHoursByTheDay = (storeHours, day) => {
  if (storeHours) {
    return storeHours[day];
  }
  return {
    open: '',
    close: ''
  };
};

export const isStoreClosed = (storeHours, date, isBopisFalseItem = false) => {
  const isTmrwdate = date.getTime() > getTodayDate().getTime();
  const day = DAYS_OF_THE_WEEK[date.getDay()];
  const closeTime = getStoreHoursByTheDay(storeHours, day)?.close;
  const priorToCloseAdjustment = isBopisFalseItem ? 0 : (bopisCutOffBeforeStoreClose || 3);
  const closeHour = closeTime?.split(':')[0] - priorToCloseAdjustment;
  const closeMinutes = closeTime?.split(':')[1];
  const hour = date.getHours();
  const minutes = date.getMinutes();
  // For tmrw date, Store is closed only if the store hours is 0:00
  const storeClosed = (parseInt(closeHour, 10) <= 0) && (parseInt(closeMinutes, 10) <= 0);
  if (isTmrwdate) {
    if (storeClosed) return true;
    return false;
  }
  if (closeMinutes === '00') {
    return hour >= closeHour;
  }
  if (hour - closeHour >= 1) {
    return true;
  }
  return hour >= closeHour && minutes >= closeMinutes;
};

export const getHolidayDate = (year, month, week, day) => {
  let tempDay = day;
  let tempMonth = month;
  const firstDay = 1;
  if (week < 0) {
    tempMonth += 1;
  }
  const date = new Date(year, tempMonth, (week * 7) + firstDay);
  if (tempDay < date.getDay()) {
    tempDay += 7;
  }
  date.setDate(date.getDate() - date.getDay() + tempDay);
  return date;
};

export const isThanksgivingDay = (dateToCheckForHoliday) => {
  const holidayDateToCheckDate = checkDate(getHolidayDate(getTodayDate().getFullYear(), 10, 3, 4));
  return dateToCheckForHoliday.checkDateMonth === holidayDateToCheckDate.checkDateMonth
      && dateToCheckForHoliday.checkDate === holidayDateToCheckDate.checkDate;
};

export const isChristmasDay = (dateToCheckForHoliday) => {
  const holidayDateToCheckDate = checkDate(new Date(`December 25, ${getTodayDate().getFullYear()} 00:00:00`));
  return dateToCheckForHoliday.checkDateMonth === holidayDateToCheckDate.checkDateMonth
      && dateToCheckForHoliday.checkDate === holidayDateToCheckDate.checkDate;
};

export const isHoliday = (date) => {
  const dateToCheck = checkDate(date);
  return isThanksgivingDay(dateToCheck) || isChristmasDay(dateToCheck);
};

export const showBopisDate = (hours, isBopisFalseItem = false) => {
  if (hours?.storeTimeZone) {
    storeTimeZone = timeZoneFormatted(hours.storeTimeZone);
  }

  const storeHours = hours || getDefaultStoreHours();
  const today = getTodayDate();
  const tomorrow = getTomorrowDate();
  const dayAfterTomorrow = getDayAfterTomorrowDate();

  if (isStoreClosed(storeHours, today, isBopisFalseItem) || isHoliday(today)) {
    if (isStoreClosed(storeHours, tomorrow, isBopisFalseItem) || isHoliday(tomorrow)) {
      if (
        isStoreClosed(storeHours, dayAfterTomorrow, isBopisFalseItem) ||
        isHoliday(dayAfterTomorrow)
      ) {
        return '';
      }
      const etaDate = getETADate();
      const displayDate = formatWeek(etaDate, true, formatDate(etaDate));
      return displayDate;
    }
    return 'Tomorrow';
  }
  return 'Today';
}
