/* eslint-disable */
import React, { ReactNode } from 'react';
import { useStore } from '@thd-nucleus/experience-context'
import { Metadata } from '@thd-olt-component-react/metadata';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useThdCustomer } from '@thd-olt-functional/customer-information';


type MergeProps = {
  query: string;
  mounted: boolean;
}

type ContainerProps = {
  children: ReactNode
}

export const PIPPageContainer = ({ children }: ContainerProps) => {
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const store = useStore();
  const { storeId, storeZip, isLocalized } = store;

  const merge = {
    productClientOnlyProduct: {
      queries: ['product', 'clientOnlyProduct'],
      fn: ({ query, mounted }: MergeProps) => {

        if (!mounted) return query;

        if (query === 'product' || query === 'clientOnlyProduct') {
          return 'productClientOnlyProduct';
        }
        return query;
      }
    }
  };

  const defaultVariables = {
    storeId,
    skipSubscribeAndSave: false ,
    zipCode: storeZip,
    searchTerm: null as string,
    sortBy: 'photoreview',
    startIndex: 1,
    maxItems: 6,
    filters: {
      isVerifiedPurchase: false,
      prosCons: null as string,
      starRatings: null as string
    },
    isBrandPricingPolicyCompliant: isLocalized ? isCustomerIdentified : false
  };

  return (
    <Metadata>
      <QueryProvider cacheKey="page" defaultVariables={defaultVariables} fusion merge={merge}>
        {children}
      </QueryProvider>
    </Metadata>
  );
};

PIPPageContainer.propTypes = {
};

PIPPageContainer.displayName = 'PIPPageContainer';

PIPPageContainer.defaultProps = {};
