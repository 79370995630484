import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HandleBar = () => {
  const handleBarStyles = [
    'sui-w-[10%]',
    'sui-h-[6px]',
    'sui-bg-strong',
    'sui-rounded-full',
    'sui-left-[15px]'
  ].join(' ');
  return <div className={handleBarStyles} />;
};

const SwipeableDrawer = React.forwardRef((props, ref) => {

  const {
    children,
    fixed = false,
    ...other
  } = props;

  const classes = classNames('sui-flex sui-flex-col sui-overflow-y-auto sui-bg-primary sui-shadow-lg sui-z-max sui-outline-none sui-max-w-full sui-w-full sui-rounded-t-lg sui-fixed sui-bottom-0 sui-h-auto sui-min-h-[25%] sui-max-h-[88%]', {
    'sui-max-h-[50%] sui-min-h-[50%] sui-h-[50%]': fixed
  });

  const rootCls = classNames('sui-flex sui-flex-col sui-overflow-y-auto sui-bg-primary sui-shadow-sm sui-z-max sui-outline-none sui-max-w-full sui-w-full sui-rounded-t-lg sui-fixed sui-bottom-0 sui-h-auto sui-min-h-[25%] sui-max-h-[88%]', {
    'sui-max-h-[50%] sui-min-h-[50%] sui-h-[50%]': fixed
  });

  return (
    <div
      className={rootCls}
      ref={ref}
      {...other}
    >
      {children}
    </div>
  );
});

SwipeableDrawer.displayName = 'SwipeableDrawer';

SwipeableDrawer.propTypes = {
  children: PropTypes.node,
  fixed: PropTypes.bool,
};

export { SwipeableDrawer };
