import React from 'react';
import PropTypes from 'prop-types';
import { FULFILLMENT_METHODS } from '../../../constants';

const ShippingThreshold = ({
  type,
  hasFreeShipping,
  totalCharge,
  maxCartPriceContributor,
  selected,
  isCartTiles
}) => {
  const showMultiLineDeliveryFee = isCartTiles && maxCartPriceContributor && selected;
  const getMultiLineDeliveryPrice = () => {
    return <span className="u__bold u__text--success">Included</span>;
  };

  const hasFreeShippingCondtions = [
    hasFreeShipping && type === FULFILLMENT_METHODS.APPLIANCE,
    !totalCharge && [FULFILLMENT_METHODS.STH_FFM, FULFILLMENT_METHODS.EXPRESS_DELIVERY].includes(type)
  ];

  if (hasFreeShippingCondtions.some((condition) => !!condition)) {
    return <span className="u__bold u__text--success">FREE</span>;
  }

  if ([FULFILLMENT_METHODS.STH_FFM, FULFILLMENT_METHODS.EXPRESS_DELIVERY].includes(type)) {
    if (showMultiLineDeliveryFee) {
      return getMultiLineDeliveryPrice();
    }
  }

  return null;
};

ShippingThreshold.propTypes = {
  type: PropTypes.string,
  hasFreeShipping: PropTypes.bool,
  totalCharge: PropTypes.number,
  maxCartPriceContributor: PropTypes.bool,
  selected: PropTypes.bool,
  isCartTiles: PropTypes.bool
};

ShippingThreshold.defaultProps = {
  type: null,
  hasFreeShipping: false,
  totalCharge: null,
  maxCartPriceContributor: false,
  selected: false,
  isCartTiles: false
};

export { ShippingThreshold };
