import React from 'react';
import PropTypes from 'prop-types';
import {
  arrayOf, extend, params, shape, string, useDataModel, alias
} from '@thd-nucleus/data-sources';
import { SponsoredCarousel } from './SponsoredCarousel';

export const PIPSponsoredCarousel = (props) => {

  const { itemId, storeId, border } = props;

  const variables = {
    itemId,
    storeId
  };

  const { data } = useDataModel('clientOnlyProduct', {
    skip: !itemId,
    ssr: false,
    variables
  });

  return (
    <SponsoredCarousel
      breadCrumbs={data?.product?.taxonomy?.breadCrumbs}
      pageContext={{
        schema: 'pip_sponsored',
        data: { itemId },
        isPip: true
      }}
      showSponsoredCarousel
      dynamic={{
        pageType: 'pip'
      }}
      hydrator={{
        className: 'grid',
        tag: {
          className: 'sui-bg-primary sui-rounded-base sui-mb-4 sui-max-w-screen-2xl sui-w-full sui-overflow-hidden'
        },
        scrollBuffer: 750
      }}
      border={border}
    />
  );
};

PIPSponsoredCarousel.displayName = 'PIPSponsoredCarousel';

PIPSponsoredCarousel.defaultProps = {
  storeId: undefined,
  border: false,
};

PIPSponsoredCarousel.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string,
  border: PropTypes.bool,
};

const BreadCrumbType = shape({
  browseUrl: string(),
  label: string()
});

PIPSponsoredCarousel.dataModel = extend({
  clientOnlyProduct: alias('product').params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSource: string(),
    taxonomy: shape({
      breadCrumbs: arrayOf(BreadCrumbType)
    })
  })
}, SponsoredCarousel);