import React, { ComponentType, ReactElement, useMemo } from 'react';
import { extend, withQueryProvider } from '@thd-nucleus/data-sources';
import cs from 'classnames';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { useImpression } from '@thd-olt-component-react/impression';

interface InjectedProps {
  fusionClassName?: string;
  fusionSlotName?: string;
  fusionComponentName?: string;
  fusionComponentExport?: string;
  hydratorId?: string;
  itemId: string
  businessRules: string;
  hydrator: any;
  dynamic: any;
  errorBoundary:any;
  queryProvider: any;
  version: string;
  slot: string;
  name: string;
  config: Config
  props: any;
  skipWrapper: boolean;
}

type Config = {
  slots: {
    [key: string]: any
  }
}

type BusinessRulesType = {
  itemId: string;
  componentDisplayName: string;
  children: (props: any) => ReactElement<any, any> | ReactElement<any, any>[];
  props: any;
  fusionProps: any;
  middlewareProps: any;
  components?: (React.FC & NucleusComponent)[]
}

type NucleusComponent = {
  dataModel: any,
  wraps: any;
  $$typeof: any
};

type WithLayoutOpts = {
  disableHydrator: boolean;
  waitForComponent: any;
  placeholder: any;
  displayName?: string;
}

type ChildComponent<T> = React.FC<T> & NucleusComponent;

type IBusinessRulesChild = {
  props: any | any[];
  Component: React.FC & NucleusComponent;
}

type ImpressionType = {
  ref: any;
  clickid: string;
};

export function withLayout<T extends InjectedProps>(
  ComponentProp: ChildComponent<T> | ChildComponent<T>[],
  BusinessRules?: ComponentType<BusinessRulesType>,
  opts?: WithLayoutOpts | WithLayoutOpts[],
): React.FC<Omit<T, keyof InjectedProps>> {
  let Component = ComponentProp;
  function LayoutComponent(props: Omit<T, keyof InjectedProps>) {

    const {
      fusionSlotName,
      fusionClassName,
      fusionComponentName,
      fusionComponentExport,
      hydratorId,
      version,
      config,
      slot,
      name,
      props: componentProps,
      skipWrapper,
      ...rest
    } = props as T;
    // dynamic components must be wrapped in hydrator!
    const { isDynamic, isImpressed, middleware, fusionClassName: fusionSlotCls } = config.slots[name] || {};
    const extendHOC = (Comp: ChildComponent<T>, Opts: WithLayoutOpts) => {
      if (!Comp.wraps?.queryProvider && rest.queryProvider) {
      // eslint-disable-next-line no-param-reassign
        Comp = withQueryProvider(Comp, rest.queryProvider) as ChildComponent<T>;
      }

      if (!Comp.wraps?.hydrator) {
        if ((rest.hydrator && !Opts?.disableHydrator) || isDynamic) {
        // eslint-disable-next-line no-param-reassign
          Comp = withHydrator({
            preserveCtxVal: 'clientStore',
            id: rest.hydrator?.id || hydratorId || name + '-hydrator',
            waitFor: Opts?.waitForComponent
              ? Opts.waitForComponent
              : null,
            placeholder: Opts?.placeholder,
          }, Comp) as ChildComponent<T>;
        }
      }

      if (!Comp.wraps?.dynamicComponent && rest.dynamic) {
      // eslint-disable-next-line no-param-reassign
        Comp = withDynamicComponent(Comp, { placeholder: Opts?.placeholder }) as ChildComponent<T>;
      }

      if (!Comp.wraps?.errorBoundary && rest.errorBoundary) {
      // eslint-disable-next-line no-param-reassign
        Comp = withErrorBoundary(Comp, { componentDisplayName: Comp.displayName });
      }
      return Comp;
    };

    let FirstChild: ChildComponent<T>;
    if (Array.isArray(Component)) {
      Component = Component.map((Comp, index) => extendHOC(
        Comp,
        Array.isArray(opts)
          ? (opts as WithLayoutOpts[])[index]
          : opts
      ));
      FirstChild = Component[0];
    } else {
      Component = extendHOC(Component, opts as WithLayoutOpts);
      FirstChild = Component;
    }

    const impressionProps: ImpressionType = {
      ref: null,
      clickid: null
    };

    if (isImpressed) {
      const slotSlot = config.slots[name] || {};

      const data = {
        id: slotSlot.contentfulId || '',
        name: slotSlot.components[0]?.name || '',
        component: slotSlot.components[0]?.export || '',
        position: `${slotSlot.section}${slotSlot.slot}`,
        type: 'slot',
      };

      const { ref, clickID } = useImpression({ data });

      impressionProps.ref = ref;
      impressionProps.clickid = clickID;
    }

    if (BusinessRules) {
      return (
        <BusinessRules
          itemId={rest.itemId}
          componentDisplayName={FirstChild.displayName || FirstChild.name}
          fusionProps={rest as T}
          middlewareProps={{ ...middleware?.props }}
          props={{ ...rest, ...componentProps }}
          components={Array.isArray(Component) ? Component : [Component]}
        >
          {({ props: comProps, Component: BusinessRulesComp = Component }) => {
            return (
              <div
                data-fusion-slot={fusionSlotName}
                data-fusion-component={fusionComponentName}
                data-fusion-export={fusionComponentExport}
                data-fusion-version={version}
                className={cs('fusion-slot', fusionClassName, fusionSlotCls)}
                {...impressionProps}
              >
                {Array.isArray(BusinessRulesComp) && BusinessRulesComp.map((BusinessRuleChild, index) => {
                  return (
                    <BusinessRuleChild
                      key={index}
                      slot={slot}
                      name={name}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...rest as T}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...(Array.isArray(componentProps) ? componentProps[index] : componentProps)}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...comProps}
                    />
                  );
                })}
                {!Array.isArray(BusinessRulesComp) && (
                  <BusinessRulesComp
                    slot={slot}
                    name={name}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest as T}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...(Array.isArray(componentProps) ? componentProps[0] : componentProps)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...comProps}
                  />
                )}
              </div>
            );
          }}
        </BusinessRules>
      );
    }
    if (Array.isArray(Component)) {

      if (FirstChild) {
        Component = FirstChild;
      } else {
        console.log(
            `warning, no child components found, returning null for ${name}`
        );
        return null;
      }

    }
    return (
      <>
        {skipWrapper
          ? (
            <Component
              slot={slot}
              name={name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest as T}
            />
          )
          : (
            <div
              data-fusion-slot={fusionSlotName}
              data-fusion-export={fusionComponentExport}
              data-fusion-component={fusionComponentName}
              data-fusion-version={version}
              className={cs('fusion-slot', fusionClassName, fusionSlotCls)}
              {...impressionProps}
            >
              <Component
                slot={slot}
                name={name}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest as T}
              />
            </div>
          )}
      </>
    );

  }

  if (!Array.isArray(Component)) {
    if ((opts as WithLayoutOpts)?.displayName) {
      LayoutComponent.displayName = (opts as WithLayoutOpts)?.displayName;
    } else {
      LayoutComponent.displayName = Component.displayName || Component.name;
    }
  } else if (Component?.length) {
    if ((opts as WithLayoutOpts[])?.length) {
      LayoutComponent.displayName = (opts as WithLayoutOpts[])[0].displayName;
    } else {
      LayoutComponent.displayName = Component[0].displayName || Component[0].name;
    }
  } else {
    console.warn('Layout component could not define display name');
  }
  let dm;
  if (Array.isArray(Component)) {
    dm = Component.map((comp) => (comp?.dataModel ? comp.dataModel : {}));
  } else {
    dm = [Component.dataModel ? Component : {}];
  }

  LayoutComponent.dataModel = extend({}, BusinessRules || {}, ...dm);

  return LayoutComponent;
}
