import React from 'react';
import { FulfillmentPodPlaceholder } from './FulfillmentPodPlaceholder';

export const shouldReturnNull = (conditions) => {
  return conditions.some((condition) => !!condition);
};

export const getEdgeCase = ({ data, error, isLoadingAvailability, loading, product }) => {
  const loadingScenarios = [loading, typeof data === 'undefined' && !error && !product];
  if (loadingScenarios.some((scenario) => !!scenario)) {
    return (
      <div className="sui-pt-6">
        <FulfillmentPodPlaceholder />
      </div>
    );
  }

  if (isLoadingAvailability) {
    return (
      <div className="sui-text-center" data-component="FulfillmentPodLoading">
        {/* segment-spinner: This needs to be updated with the MA stencil changes */}
        <span className="!sui-w-9 !sui-h-9 segment-spinner" />
      </div>
    );
  }
};