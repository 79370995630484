import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../typography/Typography';
import { IconButton } from '../button/IconButton';
import { Close } from '../private/icons/Close';

/**
 * `ModalHeader` contains the primary message or task to complete within the modal.
 *
 *
 * Related components: [Modal](#modal), [ModalBody](#modalbody), [ModalFooter](#modalfooter)
 *
 * Usage:
 *
 * ```jsx
 * import { ModalHeader } from '@one-thd/sui-atomic-components';
 * ```
 */
export const ModalHeader = (props) => {
  const {
    id,
    children,
    onClose
  } = props;

  const classes = 'sui-flex sui-py-2 sui-mx-6 sui-items-baseline';

  return (
    <div className={classes}>
      <div className="sui-overflow-auto">
        <Typography id={id} variant="h4" weight="bold" height="tight">
          {children}
        </Typography>
      </div>
      {onClose && (
        <div className="sui-flex sui-pl-4 xl:sui-mt-2px sui--mt-2px sui-ml-auto sui--mr-2">
          <IconButton icon={Close} onClick={onClose} aria-label="Close" />
        </div>
      )}
    </div>
  );
};

ModalHeader.displayName = 'ModalHeader';

ModalHeader.propTypes = {
  /**
   * Title of the Modal
   */
  children: PropTypes.node,
  /**
   * Attribute for the Typography component
   */
  id: PropTypes.string,
  /**
   * Function to handle a close event for Modal when Icon Button is clicked.
   */
  onClose: PropTypes.func
};