import React, { useState, Suspense, useContext } from 'react';
import {
  arrayOf,
  string,
  shape,
  number,
  func
} from 'prop-types';
import {
  extend,
  useDataModel,
  QueryProvider,
  QueryContext
} from '@thd-nucleus/data-sources';
import {
  Card,
  CardBody,
  Skeleton,
  SkeletonLine,
  SkeletonBlock,
  SkeletonContent,
  Typography
} from '@one-thd/sui-atomic-components';
import { useStore } from '@thd-nucleus/experience-context';
import { Price } from '@thd-olt-component-react/price';
import { lifeStyleProductsDataModel } from './LifeStyleDataModel';
import { detailsDataModel } from './DetailsDataModel';
import { ProductCardList } from './ProductCardList';
import { LifestyleImageWithHotspot } from './LifestyleImageWithHotspot';
import { getFilteredProduct, triggerAnalytics } from '../../helpers';
import { HOTSPOT_TYPE } from '../../constants';
import './styles/details.scss';
import './styles/overrides.scss';

const HotspotDrawerBody = ({
 imageUrl, omsIDs, hotspots, drawerIndex, tagIndex, updateIndex, mediaGalleryIndex
}) => {

  const store = useStore();
  const { storeId, zipCode } = store;
  const [ids, setIds] = useState(omsIDs);

  const showDetails = (productIds, hotspotIndex) => {
    setIds(productIds);
    updateIndex(hotspotIndex);
  };

  const { defaultVariables } = useContext(QueryContext);
  const newDefaultVariables = {
    storeId, 
    zipCode, 
    skipKPF: true, 
    skipSubscribeAndSave: true,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  const productListSkeleton = (keyIndex) => {
    return (
      <div className="sui-grid sui-gap-4 sui-grid-cols-1 xl:sui-grid-cols-2" key={keyIndex}>
        <Skeleton disablePadding="y" orientation="horizontal" className="details__loading">
          <SkeletonContent density="loose" disablePadding="x" initialSize="2/4">
            <SkeletonBlock aspect="square" />
          </SkeletonContent>
          <SkeletonContent density="loose" initialSize="4/4" grow>
            <SkeletonLine fullWidth numberOfLines={5} />
          </SkeletonContent>
        </Skeleton>
      </div>
    );
  };

  const { data, loading, error } = useDataModel('searchModel', {
    variables: {
      itemIds: ids,
      storeId
    },
  });
  const { searchModel } = data || {};
  const { products = [] } = searchModel || [];

  if (loading) {
    return (
      <>
        <LifestyleImageWithHotspot
          hotspots={hotspots}
          imageUrl={imageUrl}
          tagIndex={tagIndex}
          onClickHandler={showDetails}
          mediaGalleryIndex={mediaGalleryIndex}
          type={HOTSPOT_TYPE.DRAWER_HOTSPOTS}
        />
        { new Array(3).fill('').map((value, i) => (
          productListSkeleton(i)
        ))}
      </>
    );
  }
  if (!data || !products?.length) {
    return (
      <>
        <LifestyleImageWithHotspot
          hotspots={hotspots}
          imageUrl={imageUrl}
          tagIndex={tagIndex}
          onClickHandler={showDetails}
          mediaGalleryIndex={mediaGalleryIndex}
          type={HOTSPOT_TYPE.DRAWER_HOTSPOTS}
        />
        <Card orientation="horizontal" className="sui-border-0">
          <CardBody>
            <Typography> This item is currently unavailable </Typography>
          </CardBody>
        </Card>
      </>
    );
  }
  const filteredProducts = getFilteredProduct(products, imageUrl);
  let productSkus = [];

  if (filteredProducts.length > 0) {
    productSkus = filteredProducts.slice(0, 5);
    triggerAnalytics('product-hotspots.drawer', { itemId: productSkus[0]?.itemId });
  }

  return (
    <>
      <LifestyleImageWithHotspot
        hotspots={hotspots}
        imageUrl={imageUrl}
        tagIndex={tagIndex}
        onClickHandler={showDetails}
        mediaGalleryIndex={mediaGalleryIndex}
        type={HOTSPOT_TYPE.DRAWER_HOTSPOTS}
      />
      <QueryProvider
        dataSource="searchNav"
        defaultVariables={newDefaultVariables}
        cacheKey={`details-${drawerIndex}`}
      >
        {productSkus.length > 0 && productSkus.map((product, productIndex) => {
          return (
            <Suspense key={productIndex} fallback={productListSkeleton(productIndex)}>
              <ProductCardList
                product={product}
                anchorClass="details"
                index={productIndex}
                storeId={storeId}
                key={drawerIndex}
                // eslint-disable-next-line jsx-a11y/aria-role
                role="productCardList"
              />
            </Suspense>
          );
        })}
      </QueryProvider>
    </>

  );
};

HotspotDrawerBody.dataModel = extend({}, lifeStyleProductsDataModel, detailsDataModel, Price);

HotspotDrawerBody.propTypes = {
  imageUrl: string,
  hotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string)
  })).isRequired,
  drawerIndex: string,
  tagIndex: number,
  omsIDs: arrayOf(string),
  updateIndex: func,
  mediaGalleryIndex: number
};

HotspotDrawerBody.defaultProps = {
  imageUrl: '',
  drawerIndex: '',
  tagIndex: 0,
  omsIDs: [],
  updateIndex: null,
  mediaGalleryIndex: 0
};

export { HotspotDrawerBody };
