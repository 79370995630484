import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

const getUrlSearchParams = (param, queryString) => {
  const searchParam = new RegExp('[?&]' + param + '=([^&#]*)').exec(queryString);
  return searchParam && decodeURI(searchParam[1]);
};

export const shouldDisplayReferrerRecommendations = (referer) => {
  return /google.com|bing.com|yahoo.com|duckduckgo.com|aol.com/i.test(referer)
    || /^Shopping/.test(getUrlSearchParams('cm_mmc', referer))
    || /PIN_Shopping/.test(getUrlSearchParams('cm_mmc', referer));
};

export const shouldDisplayPipInStock = (data = {}, referer) => {
  const { product = {} } = data || {};
  if (shouldDisplayReferrerRecommendations(referer)) return false;
  if (podFulFillmentUtils.isDiscontinued(product)) return true;
  if (product?.availabilityType?.type === 'Browse Only') return podFulFillmentUtils.isOutOfStockYourStore(product);
  if (product?.availabilityType?.type === 'Online') return podFulFillmentUtils.isOutOfStockOnline(product);
  if (product?.availabilityType?.type === 'Shared') {
    return !!(podFulFillmentUtils.isOutOfStockOnline(product)
      || podFulFillmentUtils.isOutOfStockShared(product)
      || podFulFillmentUtils.isLimitedStock(product)
      || podFulFillmentUtils.isBackordered(product)
      || !podFulFillmentUtils.isATCEnabled(product));
  }
  if (product?.availabilityType?.type === 'Store Only') {
    return podFulFillmentUtils.isBuyInStore(product);
  }
  return false;
};

export const shouldDisplayPipAlternativeRecs = (data = {}, referer) => {
  return !shouldDisplayReferrerRecommendations(referer) && !shouldDisplayPipInStock(data, referer);
};

export const shouldDisplayBatterySystems = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.batItems;
};

export const shouldDisplayPipCollections = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.pipCollections;
};

export const shouldDisplayVisuallySimilar = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.visualNavigation;
};

export const shouldDisplayIrgaccessories = (data = {}) => {
  return (data?.product?.info?.recommendationFlags?.ACC);
};

export const shouldDisplayIrgcollections = (data = {}) => {
  return (data?.product?.info?.recommendationFlags?.collections
  && !shouldDisplayPipCollections(data)) && !shouldDisplayBatterySystems(data);
};

export const shouldDisplayRecsFBT = (data = {}, referer = false) => {
  return data?.product?.info?.recommendationFlags?.frequentlyBoughtTogether && !shouldDisplayPipInStock(data, referer);
};

export const shouldDisplayFbtbundle = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.bundles;
};

export const shouldDisplayFBTPMV = (setIsPMVEnabled, setIsFBTEnabled, setIsPMVAndFBTEnabled) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('enablePackagesMiniVisual', (data) => {
    setIsPMVEnabled(data?.output?.enabled || false);
  });
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('enableFrequentlyBoughtTogether', (data) => {
    setIsFBTEnabled(data?.output?.enabled || false);
  });
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('enablePMVAndFBT', (data) => {
    setIsPMVAndFBTEnabled(data?.output?.enabled || false);
  });
};

const isDiscontinued = (product) => {
  return !!(product?.availabilityType?.discontinued);
};

const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    const nearbyStore = locations.find((location) => !location.isAnchor);
    return nearbyStore;
  }
  return null;
};
const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  const localStore = locations.find((location) => location.isAnchor);
  return localStore;
};

const isBOPISHaveQuantity = (product) => {
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  if (!bopisFulfillment) {
    return false;
  }
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return pickupService?.fulfillable
    && (localStoreLocation?.inventory?.quantity > 0 || nearByStoreLocation?.inventory?.quantity > 0);
};

export const isDisplayableBasedOnFulfillment = (product) => {
  const deliveryService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return !!(!isDiscontinued(product)
    && (deliveryService
      || shippingFulfillment
      || bossFulfillment
      || isBOPISHaveQuantity(product?.fulfillment)));
};

export const isPackageRecommendationFlag = (data = {}) => {
  return !!data?.product?.info?.recommendationFlags?.packages;
};

export const isProductAvailable = (product, abcTestEnable) => {
  if (
    !product
    || (abcTestEnable && isPackageRecommendationFlag(product) && !isDisplayableBasedOnFulfillment(product))
  ) {
    return null;
  }
  return product;
};

export const shouldDisplayConfigurator = (data = {}, isServer) => {
  return (
    !isServer
    && data?.product?.identifiers?.productType === 'CONFIGURABLE_BLINDS'
  );
};

export const isBathCategory = (data = {}) => {
  if (data?.product?.taxonomy?.breadCrumbs) {
    return (data?.product?.taxonomy?.breadCrumbs[0]?.label.toLowerCase() === 'bath');
  }
  return false;
};